<template>
    <b-container class="py-5">
        <section
            v-for="(service, i) in services"
            :key="i"
            class="service-section"
        >
            <b-row>
                <b-col
                    cols="12"
                    lg="6"
                    class="order-0 d-flex align-items-center justify-content-center"
                    :class="i % 2 != 0 ? 'order-lg-0' : 'order-lg-1'"
                >
                    <div
                        class="service-images-container w-100 mx-auto"
                        style="max-width: 500px"
                        data-aos="fade-up"
                        data-aos-duration="3000"
                    >
                        <div class="image-1">
                            <div class="service-image-card-border rounded">
                                <div class="service-image-card rounded">
                                    <img
                                        :src="
                                            $store.getters['app/domainHost'] +
                                                '/' +
                                                service.documents[0].path
                                        "
                                        class="w-100 rounded"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="image-2">
                            <div class="service-image-card-border rounded">
                                <div class="service-image-card rounded">
                                    <img
                                        :src="
                                            $store.getters['app/domainHost'] +
                                                '/' +
                                                service.documents[0].path
                                        "
                                        class="w-100 rounded"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    cols="12"
                    lg="6"
                    class="order-1"
                    :class="i % 2 != 0 ? 'order-lg-1' : 'order-lg-0'"
                >
                    <div
                        class="service-images-container-border mx-auto rounded"
                        style="max-width: 600px"
                    >
                        <div
                            class="service-description-container w-100 rounded"
                        >
                            <h3>{{ service.name }}</h3>
                            <p class="m-0">{{ service.description }}</p>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </section>
    </b-container>
</template>

<script>
import { handleLangChange } from "@/libs/event-bus";
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            services: state => state.webServices.services
        })
    },
    metaInfo() {
        return {
            titleTemplate: "Awija - " + this.$t("website.services"),
            htmlAttrs: {
                lang: this.$i18n.locale
            },
            meta: [
                {
                    name: "description",
                    content: "description"
                },
                {
                    name: "keywords",
                    content: "keywords, keywords, keywords, ..."
                }
            ]
        };
    },
    created() {
        this.fetchServiesWebsite();
        handleLangChange(this.fetchServiesWebsite);
    },
    methods: {
        ...mapActions(["fetchServiesWebsite"])
    }
};
</script>

<style scoped lang="scss">
.service-section {
    padding: 50px 0;
    color: #fff;
    .service-images-container {
        height: 400px;
        position: relative;
        .image-1,
        .image-2 {
            perspective: 500px;
            transform-style: preserve-3d;
            position: absolute;
            transform: translateY(-100px);
            height: 100%;
            width: 100%;
            .service-image-card-border {
                position: relative;
                transform: scale(0.7) rotate3d(0, 4, 1, 12deg);
                height: 100%;
                width: 100%;
                padding-top: 3px;
                padding-right: 2px;
                border-top: solid 1px #fff;
                border-right: solid 1px #fff;
                .service-image-card {
                    background: var(--custom-dark);
                    height: 100%;
                    width: 100%;
                }
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .image-1 {
            top: 0;
        }
        .image-2 {
            top: 60px;
            right: 60px;
        }
    }
    .service-images-container-border {
        padding: 3px;
        background: linear-gradient(
            0deg,
            #878787,
            #ffffff,
            #6d6d6d,
            #ffffff,
            #949494
        );
        .service-description-container {
            padding: 2rem 16px;
            background: radial-gradient(
                104.33% 375.58% at 97.5% 94.44%,
                rgba(196, 196, 196, 0.07) 0%,
                rgba(196, 196, 196, 0) 100%
            );
            background-color: var(--custom-dark);
            box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
                inset 2px -2px 4px rgba(0, 0, 0, 0.1);
            filter: drop-shadow(-4px 4px 30px rgba(0, 0, 0, 0.25))
                drop-shadow(4px -4px 30px rgba(255, 255, 255, 0.08));
            backdrop-filter: blur(80px);
            h3 {
                color: #fff;
                text-align: center;
                margin: 0;
            }
            p {
                padding: 2rem 0;
                text-align: justify;
            }
        }
    }
    &:nth-of-type(2n) {
        .service-images-container {
            .image-1,
            .image-2 {
                .service-image-card-border {
                    transform: scale(0.7) rotate3d(0, 4, 1, -12deg);
                    border-left: solid 1px #fff;
                    border-right: none;
                }
            }
            .image-2 {
                right: auto;
                left: 60px;
            }
        }
    }
}
[dir="ltr"] {
    .service-section {
        .service-images-container {
            .image-1,
            .image-2 {
                .service-image-card-border {
                    transform: scale(0.7) rotate3d(0, 4, 1, -12deg);
                }
            }
        }
        &:nth-of-type(2n) {
            .service-images-container {
                .image-1,
                .image-2 {
                    .service-image-card-border {
                        transform: scale(0.7) rotate3d(0, 4, 1, 12deg);
                    }
                }
            }
        }
    }
}
</style>
